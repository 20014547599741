import React from "react";
import LoadingUI from "../LoadingUI/LoadingUI";

export default function FullPageLoadingUI({ message }) {
  return (
    <div
      style={{ width: "100%", minHeight: "100vh" }}
      className="d-flex justify-content-center align-items-center"
    >
      <LoadingUI message={message} />
    </div>
  );
}
