import React, { useContext, useEffect, useState } from "react";
import { Alert, Container, Modal } from "react-bootstrap";
import { Link, Outlet, useParams } from "react-router-dom";
import loadingScreen from "../images/loading-screen.gif";
import { PublicItemContenxt } from "../contexts/PublicItemContenxt";
import axios from "axios";
import LoadingUI from "../components/LoadingUI/LoadingUI";

const StoreRequire = () => {
  let params = useParams();
  let { publicItems, setPublicItems } = useContext(PublicItemContenxt);
  let { store } = publicItems;
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const [showPlanExpiredWindow, setShowPlanExpiredWindow] = useState(false);

  const getStoreDetails = () => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .get("/api/store-details/" + params.storeID)
        .then((response) => {
          setLoadingCompleted(true);
          const data = response?.data?.data;
          if (data?.plan_expiry_date && !data?.plan_expired) {
            const newPublicItems = {
              store: data,
              categories: data?.categories || [],
              products: data?.products || [],
            };

            setPublicItems(newPublicItems);
          } else {
            setShowPlanExpiredWindow(true);
          }
        })
        .catch((error) => {
          setLoadingCompleted(true);
          console.log(error);
        });
    });
  };

  useEffect(() => {
    if (!loadingCompleted) {
      getStoreDetails();
    }
  }, [loadingCompleted]);

  if (!loadingCompleted) return <LoadingUI message="Loading details..." />;

  return params.storeID ? (
    store?.slug ? (
      <Outlet />
    ) : (
      <Container>
        {showPlanExpiredWindow ? (
          <Modal
            show={showPlanExpiredWindow}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header style={{ backgroundColor: "#662d91" }}>
              <Modal.Title className="text-white">
                Subscription Expired
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              The subscription for this store has expired. If you are the owner
              of this store,{" "}
              <Link to="/subscribe" style={{ color: "#662d91" }}>
                click here
              </Link>{" "}
              to renew your subscription.
            </Modal.Body>
            <Modal.Footer className="border-0"></Modal.Footer>
          </Modal>
        ) : (
          <Alert variant="warning" className="mt-4">
            No stores found!
          </Alert>
        )}
      </Container>
    )
  ) : (
    "404"
  );
};

export default StoreRequire;
