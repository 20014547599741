import React from "react";
import { Accordion, Nav } from "react-bootstrap";
import { MdDashboard } from "react-icons/md";
import { FaBook, FaUsers } from "react-icons/fa";
import { Outlet } from "react-router-dom";
import AppNavLink from "../../../components/AppNavLink/AppNavLink";
const navItems = [
  {
    name: "Dashboard",
    Icon: MdDashboard,
    to: "/admin/dashboard",
  },
  {
    name: "Customers",
    Icon: FaUsers,
    to: "/admin/customers",
  },
  {
    name: "Accounts",
    Icon: FaBook,
    matchPath: "/admin/accounts/",
    childrens: [
      {
        name: "Invoice",
        to: "/admin/accounts/invoice",
      },
    ],
  },
];

const AdminDashboardLayout = () => {
  return (
    <div className="d-flex flex-row w-100">
      <div className="admin-nav-area">
        <Accordion>
          <Nav as="ul" className="small mt-3">
            {navItems.map((navItem, index) => (
              <AppNavLink key={index} index={index} navItem={navItem} />
            ))}
          </Nav>
        </Accordion>
      </div>
      <div className="admin-content-area p-3">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminDashboardLayout;
