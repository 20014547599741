const authReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE":
      localStorage.setItem(
        "MABIZ_DIGITAL_STORE_APP",
        JSON.stringify(action.payload)
      );

      return { ...action.payload };

    case "DELETE":
      return undefined;

    default:
      return state;
  }
};

export default authReducer;
