import axios from "axios";
import React, { useContext } from "react";
import { LoginContext } from "../../contexts/LoginContext";

export default function AxiosInterceptors({ children }) {
  let { user, userDispatch } = useContext(LoginContext);
  const getUserData = () => {
    return JSON.parse(localStorage.getItem("MABIZ_DIGITAL_STORE_APP"));
  };
  const getAuthToken = () => {
    let userData = getUserData();
    return userData && userData.token ? userData.token : undefined;
  };

  // setting axios config
  axios.interceptors.request.use(
    (config) => {
      config.baseURL = process.env.REACT_APP_API_BASE_URL;
      config.withCredentials = true;
      config.headers.post["Content-Type"] = "application/json";
      config.headers.post["Accept"] = "application/json";
      config.headers.common["Authorization"] = `Bearer ${getAuthToken()}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response?.status === 401 && user?.token && user?.email) {
        userDispatch({
          type: "UPDATE",
          payload: { ...user, sessionExpired: true },
        });
      }
      if (error?.response?.data?.plan_expired) {
        user.plan_expired = true;
        userDispatch({
          type: "UPDATE",
          payload: user,
        });
      }
      return Promise.reject(error);
    }
  );

  return children;
}
