import axios from "axios";
import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { LoginContext } from "../../contexts/LoginContext";
import Cart from "../cart/Cart";
import Search from "../search/Search";
import isStoreView from "../../helper/isStoreView";

const Header = () => {
  const location = useLocation();
  const params = useParams();
  params.storeID &&
    localStorage.setItem("AM_DIGITAL_MENU_STORE", params.storeID);
  const navigate = useNavigate();
  const { user, userDispatch } = useContext(LoginContext);
  const logout = (e) => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post("/api/logout")
        .then((res) => {
          if (res?.status === 200) {
            localStorage.removeItem("MABIZ_DIGITAL_STORE_APP");
            userDispatch({ type: "DELETE" });
            navigate("/login");
            return;
          }

          console.error(
            "Something went wrong, please refresh the page and try again.",
            res
          );
        })
        .catch((error) => {
          console.error(
            "Something went wrong, please refresh the page and try again.",
            error
          );
        });
    });
  };

  return (
    <Navbar sticky="top" variant="dark" expand="lg" className="brand-bg">
      <Container>
        <Navbar.Brand
          as={Link}
          to={
            user && location.pathname.indexOf("dashboard") === 1
              ? "/dashboard"
              : params.storeID
              ? "/store/" + params.storeID
              : "/"
          }
        >
          Digital Store
        </Navbar.Brand>
        <div className="d-flex flex-row align-items-center order-lg-3">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {params.storeID && isStoreView(location.pathname) ? (
            <div className="d-none d-md-flex flex-row align-items-center">
              {!location.pathname.includes("search/") ? (
                <Nav.Link
                  as={NavLink}
                  to={`/search/${params.storeID}`}
                  className="px-0"
                >
                  <Search />
                </Nav.Link>
              ) : null}
              {!location.pathname.includes("/cart/") ? (
                <Nav.Link
                  as={NavLink}
                  to={`/cart/${params.storeID}`}
                  className="px-0"
                >
                  <Cart />
                </Nav.Link>
              ) : null}
            </div>
          ) : null}
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {user ? (
              <>
                <NavDropdown
                  title={user.name}
                  id="basic-nav-dropdown"
                  align="start"
                >
                  <NavDropdown.Item as={Link} to="/dashboard">
                    <div className="d-flex flex-row align-items-center">
                      <span className="ms-2 my-0">Dashboard</span>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/dashboard/profile">
                    <div className="d-flex flex-row align-items-center">
                      <span className="ms-2 my-0">Profile</span>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/dashboard/billing">
                    <div className="d-flex flex-row align-items-center">
                      <span className="ms-2 my-0">Billing</span>
                    </div>
                  </NavDropdown.Item>

                  {user.role === "admin" ? (
                    <NavDropdown.Item as={Link} to="/admin">
                      <div className="d-flex flex-row align-items-center">
                        <span className="ms-2 my-0">Admin area</span>
                      </div>
                    </NavDropdown.Item>
                  ) : null}

                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    as={"div"}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <button
                      className="w-100 rounded app-custom-btn-1 d-flex flex-row justify-content-center align-items-center py-2"
                      onClick={logout}
                    >
                      <FontAwesomeIcon icon={faSignOut} />
                      <span className="ms-2 my-0">Logout</span>
                    </button>
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <>
                <Nav.Link as={NavLink} to="/login">
                  Login
                </Nav.Link>
                <Nav.Link as={NavLink} to="/register">
                  Register
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
