import React, { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import { AppContext } from "../contexts/AppContext";
import StoreMobileFooter from "../components/store-mobile-footer/StoreMobileFooter";

const DashboardLayout = ({ children }) => {
  const [appSettings, setAppSettings] = useState(useContext(AppContext));
  return (
    <AppContext.Provider value={{ appSettings, setAppSettings }}>
      <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
        <Header />
        <div className="d-flex flex-column flex-md-row flex-fill">
          <Outlet />
          {children ?? null}
        </div>
        <footer className="mt-auto border-top">
          <div className="container py-2 small d-flex justify-content-center">
            &copy; 2022 - {new Date().getFullYear()}
            <a
              href="https://mabiztech.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-1"
            >
              MAbiz Technologies.
            </a>
            All rights reserved.
          </div>
        </footer>
        <StoreMobileFooter />
      </div>
    </AppContext.Provider>
  );
};

export default DashboardLayout;
