import React, { useContext, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { LoginContext } from "../../contexts/LoginContext";
import { toast } from "react-toastify";
import axios from "axios";
import { FaArrowRight } from "react-icons/fa";
import FormError from "../FormError";
import ThemeButton from "../theme-button/ThemeButton";

export default function SessionOutLogInForm({ show }) {
  const { user, userDispatch } = useContext(LoginContext);
  const [formData, setformData] = useState({
    email: user?.email,
    password: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInput = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .get("/sanctum/csrf-cookie")
      .then((response) => {
        axios
          .post("/api/login", formData)
          .then((res) => {
            setLoading(false);
            if (res.status === 200) {
              toast.success(
                "Your account has been successfully logged in and you will be redirected to your dashboard. Please wait..."
              );

              setformData({
                email: "",
                password: "",
              });

              localStorage.setItem(
                "MABIZ_DIGITAL_STORE_APP",
                JSON.stringify(res.data.user)
              );

              userDispatch({
                type: "UPDATE",
                payload: res.data.user,
              });

              window.location.href = window.location.href;
            } else {
              toast.error(
                "Something went wrong, please refresh the page and try again."
              );
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.data.errors) {
              setFormError(error.response.data.errors);
            } else if (error.response.status === 401) {
              toast.error(error.response.data.message);
            } else {
              toast.error(
                "Something went wrong, please refresh the page and try again."
              );
            }
          });
      })
      .catch((error) => {
        toast.error(
          "Something went wrong, please refresh the page and try again."
        );
        setLoading(false);
      });
  };

  return show ? (
    <Modal centered show>
      <Modal.Body>
        <div className="sm-form glass-form">
          <h5 className="text-dark">Session has expired.</h5>
          <p className="small text-muted">
            Your session has timed out due to inactivity. Please log in again to
            continue.
          </p>
          <Form className="my-3" onSubmit={handleLogin}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInput}
                required
              />
              {formError.password && <FormError error={formError.password} />}
            </Form.Group>

            <ThemeButton
              className="w-100 rounded app-custom-btn-1 d-flex flex-row justify-content-center align-items-center"
              disabled={!formData.email || !formData.password || loading}
            >
              {!loading ? (
                <>
                  <span>Login</span>
                  <FaArrowRight className="ms-2" size={15} />
                </>
              ) : (
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
            </ThemeButton>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  ) : null;
}
