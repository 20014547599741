import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import isStoreView from "../../helper/isStoreView";
import { FaHome, FaRegUserCircle, FaSearch } from "react-icons/fa";
import Cart from "../cart/Cart";

export default function StoreMobileFooter() {
  const location = useLocation();
  const params = useParams();
  const homePath = "/store/" + params?.storeID;
  const searchPath = "/search/" + params?.storeID;
  const accountPath = "/dashboard/";
  const cartPath = "/cart/" + params?.storeID;

  const setActiveNaVClass = (matchingPath) => {
    return location.pathname === matchingPath ? "text-dark" : "text-secondary";
  };

  return isStoreView(location.pathname) ? (
    <div
      id="store-footer-nav"
      className="d-md-none bg-light border-top shadow-sm"
    >
      <div className="container px-4 py-3 d-flex flex-row justify-content-between gap-3">
        <div>
          <Link
            to={homePath}
            className={`d-flex flex-column align-items-center gap-1 ${setActiveNaVClass(
              homePath
            )}`}
          >
            <FaHome size={22} />
            <span className="small">Home</span>
          </Link>
        </div>
        <div>
          <Link
            to={searchPath}
            className={`d-flex flex-column align-items-center gap-1 ${setActiveNaVClass(
              searchPath
            )}`}
          >
            <FaSearch size={22} />
            <span className="small">Search</span>
          </Link>
        </div>
        <div>
          <Link
            to={accountPath}
            className={`d-flex flex-column align-items-center gap-1 ${setActiveNaVClass(
              accountPath
            )}`}
          >
            <FaRegUserCircle size={22} />
            <span className="small">Account</span>
          </Link>
        </div>
        <div>
          <Link
            to={cartPath}
            className={`d-flex flex-column align-items-center gap-1 position-relative ${setActiveNaVClass(
              cartPath
            )}`}
          >
            <Cart mobileBottomNav />
          </Link>
        </div>
      </div>
    </div>
  ) : null;
}
