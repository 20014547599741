import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import SubscriptionExpiredAlert from "../components/SubscriptionExpiredAlert/SubscriptionExpiredAlert";
import VerifyEmailAddress from "../components/VerifyEmailAddress/VerifyEmailAddress";
import FullPageLoadingUI from "../components/full-page-loading-ui/FullPageLoadingUI";
import axios from "axios";

const AuthRequire = () => {
  const location = useLocation();
  let { user, userDispatch } = useContext(LoginContext);
  const checkIsRestrictedPage = ![
    "/dashboard/profile",
    "/checkout/pro",
    "/dashboard/billing",
  ].includes(location.pathname);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const getUserAuthData = async () => {
      try {
        setloading(true);
        if (user?.token) {
          const apiResult = await axios.get("/api/user");
          const latestUserData = apiResult?.data?.data;
          if (apiResult.status === 200 && latestUserData?.name) {
            userDispatch({
              type: "UPDATE",
              payload: { ...user, ...latestUserData },
            });
          }
        }
      } catch (error) {
        console.error("error", error);
      } finally {
        setloading(false);
      }
    };

    getUserAuthData();
  }, []);

  return loading ? (
    <FullPageLoadingUI message="Checking user details" />
  ) : !user ? (
    <Navigate to="/login" />
  ) : !user.email_verified_at ? (
    <VerifyEmailAddress />
  ) : user.role !== "admin" &&
    !user.package &&
    location.pathname !== "/checkout/pro" ? (
    <Navigate to="/subscribe" />
  ) : user.role !== "admin" &&
    user?.package?.plan_expired &&
    checkIsRestrictedPage ? (
    <SubscriptionExpiredAlert />
  ) : user.role !== "admin" &&
    user.package &&
    user.package.plan !== "basic" &&
    !user.package.is_paid &&
    checkIsRestrictedPage ? (
    <Navigate to={"/dashboard/profile"} />
  ) : (
    <Outlet />
  );
};

export default AuthRequire;

// !location.pathname.includes("/checkout") &&
// !location.pathname.includes("/dashboard/billing/view-invoice")
