import React, { useContext, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { LoginContext } from "../../contexts/LoginContext";
import { FaArrowRight } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import FormError from "../FormError";

const VerifyEmailAddress = () => {
  let { user, userDispatch } = useContext(LoginContext);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [reSending, setReSending] = useState(false);
  const [formError, setFormError] = useState({});

  const [verificationCode, setVerificationCode] = useState(
    searchParams.get("code") || ""
  );

  const handleVerifyEmailAddress = async (event) => {
    event?.preventDefault();

    try {
      setLoading(true);
      const apiResponse = await axios.post("/api/verify-email-address", {
        email_verification_code: verificationCode,
      });
      if (apiResponse.status === 200 && apiResponse?.data?.user) {
        const updatedUserData = { ...user, ...apiResponse.data.user };

        userDispatch({
          type: "UPDATE",
          payload: updatedUserData,
        });

        toast.success(apiResponse.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong, please refresh the page and try again."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendEmailVerificationCode = async (event) => {
    event?.preventDefault();

    try {
      setReSending(true);
      const apiResponse = await axios.post(
        "/api/re-send-email-verification-code"
      );

      if (apiResponse.status === 200 && apiResponse?.data?.status) {
        toast.success(apiResponse.data?.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "Something went wrong, please refresh the page and try again."
      );
    } finally {
      setReSending(false);
    }
  };

  return (
    <Modal show={true} backdrop="static" keyboard={false} centered>
      <Modal.Header style={{ backgroundColor: "#662d91" }}>
        <Modal.Title className="text-white h6 text-uppercase">
          Verify your email address
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="h6 fw-bold">Please check your inbox</p>
        <p className="text-secondary">
          To start using digital store, confirm your email address. We've sent a
          code to <span className="text-dark fw-bold">{user?.email}</span>
        </p>
        <Form onSubmit={handleVerifyEmailAddress}>
          <Form.Group className="mb-3">
            <Form.Control
              className="fw-bold"
              placeholder="Enter verification code"
              onChange={(event) => setVerificationCode(event.target.value)}
              value={verificationCode || ""}
            />
            {formError.email_verification_code && (
              <FormError error={formError.email_verification_code} />
            )}
          </Form.Group>
          <button
            className="w-100 rounded app-custom-btn-1 d-flex flex-row justify-content-center align-items-center"
            type="submit"
            disabled={!verificationCode || loading}
          >
            {!loading ? (
              <>
                <span>Verify Me</span>
                <FaArrowRight className="ms-2" size={15} />
              </>
            ) : (
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </button>
          <div className="text-center my-3">
            Don't receive an email?
            {reSending ? (
              <span className="text-success ms-1">
                <b>Sending...</b>
              </span>
            ) : (
              <a
                href="#"
                className="text-success ms-1"
                onClick={handleResendEmailVerificationCode}
              >
                <b>Resend</b>
              </a>
            )}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0"></Modal.Footer>
    </Modal>
  );
};

export default VerifyEmailAddress;
