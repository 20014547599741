import React, { useContext, useEffect, useReducer, useState } from "react";
import { LoginContext } from "../contexts/LoginContext";
import { Spinner } from "react-bootstrap";
import authReducer from "../reducers/authReducer";
import FullPageLoadingUI from "../components/full-page-loading-ui/FullPageLoadingUI";

const AuthProvider = ({ children }) => {
  const [loading, setloading] = useState(true);
  const [user, userDispatch] = useReducer(
    authReducer,
    useContext(LoginContext)
  );

  useEffect(() => {
    setloading(true);
    let userData = localStorage.getItem("MABIZ_DIGITAL_STORE_APP");
    if (userData) {
      userDispatch({
        type: "UPDATE",
        payload: JSON.parse(userData),
      });
    }
    setloading(false);
  }, []);

  return (
    <LoginContext.Provider value={{ user, userDispatch }}>
      {loading ? <FullPageLoadingUI /> : children}
    </LoginContext.Provider>
  );
};

export default AuthProvider;
